import React from "react";
import "./styles.scss";
import {useEffect, useState} from "react";
import {useWindowWidth} from "../../../services/hooks";

const SpringPdpLimitedTimeBanner = () => {
  const [isMobile, setIsMobile] = useState()
  const windowWidth = useWindowWidth()

  useEffect(() => {
    setIsMobile(windowWidth <= 768)
  }, [windowWidth])

  return (
    <div className="spring-pdp-limited-time-banner">
      <div className="flower"></div>
      <span className="description">{isMobile ? "limited time only\nnow through april 1" : "limited time only — now through april 1"}</span>
      <div className="flower"></div>
    </div>
  )
}

export default SpringPdpLimitedTimeBanner;
