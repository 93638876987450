import React from "react"
import { navigate } from "gatsby"
import { Modal } from 'react-bootstrap'
import WebpImage from "../WebpImage"

const LeavingHubbleModal = ({show, setShow, link, dataLayerFunction, setCurrentItem}) => {
  const handleCCNav = (e) => {
    // e.preventDefault()
    if (dataLayerFunction) {
      dataLayerFunction()
    }
    navigate(e.target.href)
  }

  return (
    <Modal
      show={show}
      className="cc-modal"
      onHide={() => {setCurrentItem({ id: "", type: "contacts" }); setShow(false)}}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <span class="cc-logo">
            <WebpImage fileName="assets/ContactsCartCTA/ContactsCart-Logo.png" alt="ContactsCart Logo" height={15} />
          </span>
          <span className="plus">+</span>
          <WebpImage fileName="assets/ContactsCartCTA/Hubble-Logo.png" alt="Hubble Logo" height={33}/>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p className="cc-modal-title">Making sure you saw this—and knew.</p>
        <p className="modal-copy">You’re about to leave Hubble Contacts for our partner site ContactsCart.</p>
        <button className="btn btn-primary stay-btn" onClick={() => {setCurrentItem({ id: "", type: "contacts" }); setShow(false)}}>Stay on Hubblecontacts.com</button>
        <a onClick={handleCCNav} href={link} className="btn btn-primary cc-cta-btn">Visit ContactsCart.com</a>
      </Modal.Body>
    </Modal>
  )
}

export default LeavingHubbleModal
