import ProductSlider from "../ProductSlider"
import ProductDetailsAvg, { ProductDescriptionAvg } from "../ProductDetailsAvg"
import React, {useEffect, useMemo, useState} from "react"
import { useWindowWidth } from "../../services/hooks"
import "./styles.scss"
import { navigate } from "gatsby"
import {productInfo as prescriptionData} from "../Intake/Prescription/mockedData";
import ProductTag from "../ProductTag";

const AvgProductTopSection = ({
  item,
  currentOptionIndex,
  setCurrentOptionIndex,
}) => {
  const width = useWindowWidth()
  const [isMobile, setIsMobile] = useState(false)
  const prescriptionSettings = useMemo(
    () => prescriptionData[item.options[currentOptionIndex].id],
    [currentOptionIndex]
  )

  useEffect(() => {
    if (width <= 1110 && !isMobile) setIsMobile(true)
    else if (width > 1110 && isMobile) setIsMobile(false)
  }, [width])

  return (
    <div className="avg-product-top-section">
      {isMobile && (
        <div className="title-wrapper">
          <ProductTag text="Get 40% OFF" color="red"/>
          <h1 className="title">{item.title}</h1>
        </div>
      )}
      <div className="left-bar">
        {!isMobile && <ProductTag text="Get 40% OFF" color="red"/>}
        <ProductSlider
          product={item.options[currentOptionIndex]}
          type="contacts"
          slides={item.options[currentOptionIndex].slides || [`Pages/Intake/Products/${item.options[currentOptionIndex].image}.png`]}
          forcePagination
          currentVariant={currentOptionIndex + 1}
        />
        {(item.options.length + Object.values(prescriptionSettings).filter(e => Array.isArray(e) && e.length > 1).length >= 4) && !isMobile && (
          <ProductDescriptionAvg item={item} />
        )}
      </div>
      <ProductDetailsAvg
        currentOptionIndex={currentOptionIndex}
        setCurrentOptionIndex={setCurrentOptionIndex}
        type="contacts"
        item={item}
      />
    </div>
  )
}

export default AvgProductTopSection
